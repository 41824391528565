 function handleContent(param) {
    let content = param;
    content = param.replace(/<br>([^<]*?)/gi, '$1');//去掉br标签
    content = content.replace(/<(?!img)([(a-zA-Z1-6]+)(\s*[^>]*)?>/g, "<$1 style='margin-bottom:15px;font-weight:400;color:#333;text-align:left;text-indent: 2em; font-family: 宋体; font-size: 12pt;'>");//替换样式
    content = content.replace(/<([\/]?)(p)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//p标签全部替换成div标签
    content = content.replace(/<([\/]?)(h1)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h1标签全部替换成div标签
    content = content.replace(/<([\/]?)(h2)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h2标签全部替换成div标签
    content = content.replace(/<([\/]?)(h3)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h3标签全部替换成div标签
    content = content.replace(/<([\/]?)(h4)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h4标签全部替换成div标签
    content = content.replace(/<([\/]?)(h5)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h5标签全部替换成div标签
    content = content.replace(/<([\/]?)(h6)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h6标签全部替换成div标签
    let regex0 = new RegExp("(i?)(\<img)([^\>]+\>)", "gmi")
    //content = content.replace(regex0, "$2 style='width:300px;margin-left:400px;' $3");//修改img标签样式
    // 去掉制表符\t、空格&nbsp;和换行符\n
    content = content.replace(/\s{2,}/gi, '')
    content = content.replace(/\&nbsp;+/gi, '')
    content = content.replace(/\t+/gi, '')
    content = content.replace(/\n+/gi, '')
    // 去除含有空格没有内容的div
    content = content.replace(/<div style='margin-bottom:15px;font-weight:400;color:#333;text-align:left;text-indent: 2em; font-family: 宋体; font-size: 12pt;'>\s+<\/div>/g, '')
    content = content.replace(/<div style='margin-bottom:15px;font-weight:400;color:#333;text-align:left;text-indent: 2em; font-family: 宋体; font-size: 12pt;'>\s?<\/div>/g, '')
    content = content.replace(/<div style='margin-bottom:15px;font-weight:400;color:#333;text-align:left;text-indent: 2em; font-family: 宋体; font-size: 12pt;'>\s*<\/div>/g, '')
    return content;
}
function handleContent2(param) {
    let content = param;
    content = param.replace(/<br>([^<]*?)/gi, '$1');//去掉br标签
    content = content.replace(/<(?!img)([(a-zA-Z1-6]+)(\s*[^>]*)?>/g, "<$1 style='margin-bottom:15px;font-weight:400;color:#333;text-align:left; font-family: 宋体; font-size: 12pt;'>");//替换样式
    content = content.replace(/<([\/]?)(p)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//p标签全部替换成div标签
    content = content.replace(/<([\/]?)(h1)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h1标签全部替换成div标签
    content = content.replace(/<([\/]?)(h2)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h2标签全部替换成div标签
    content = content.replace(/<([\/]?)(h3)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h3标签全部替换成div标签
    content = content.replace(/<([\/]?)(h4)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h4标签全部替换成div标签
    content = content.replace(/<([\/]?)(h5)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h5标签全部替换成div标签
    content = content.replace(/<([\/]?)(h6)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>');//h6标签全部替换成div标签
    let regex0 = new RegExp("(i?)(\<img)([^\>]+\>)", "gmi")
    content = content.replace(regex0, "$2 style='width:300px;margin-left:400px;' $3");//修改img标签样式
    // 去掉制表符\t、空格&nbsp;和换行符\n
    content = content.replace(/\s{2,}/gi, '')
    content = content.replace(/\&nbsp;+/gi, '')
    content = content.replace(/\t+/gi, '')
    content = content.replace(/\n+/gi, '')
    // 去除含有空格没有内容的div
    content = content.replace(/<div style='margin-bottom:15px;font-weight:400;color:#333;text-align:left; font-family: 宋体; font-size: 12pt;'>\s+<\/div>/g, '')
    content = content.replace(/<div style='margin-bottom:15px;font-weight:400;color:#333;text-align:left; font-family: 宋体; font-size: 12pt;'>\s?<\/div>/g, '')
    content = content.replace(/<div style='margin-bottom:15px;font-weight:400;color:#333;text-align:left; font-family: 宋体; font-size: 12pt;'>\s*<\/div>/g, '')
    return content;
}
export  {
    handleContent,
    handleContent2
}
