<template>
  <div class="policy-detail-container">
    <div class="banner-limit policy-detail-content" style="min-height: 24px">
      <img src="~@/assets/images/wz_icon.png" alt="" style="" />
      <el-breadcrumb
        v-if="!showFlag"
        separator-class="el-icon-arrow-right"
        style="font-size: 18px; display: inline-block; margin-left: 8px"
      >
        <el-breadcrumb-item :to="{ path: '/policyLibrary' }"
          >政策检索</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/searchList' }" v-if="linkType"
          >分类搜索</el-breadcrumb-item
        >
        <el-breadcrumb-item>政策详情</el-breadcrumb-item>
      </el-breadcrumb>
      <el-breadcrumb
        v-else
        separator-class="el-icon-arrow-right"
        style="font-size: 18px; display: inline-block; margin-left: 8px"
      >
        <el-breadcrumb-item :to="{ path: '/policyMatchReport' }"
          >返回</el-breadcrumb-item
        >
        <el-breadcrumb-item>政策原文</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div
      class="banner-limit flex-column flex-start policy-detail-content"
      ref="noticeDDetail"
    >
      <div v-if="itemData" class="flex-row-between detail-wrap">
        <div class="flex-column flex-start detail-left">
          <!-- 标题 -->
          <div
            class="detail-title flex-center"
            style="border-bottom: 4px solid #333; padding-bottom: 10px"
          >
            <h2>{{ itemData.name }}</h2>
            <div class="flex-row flex-center title-wrap">
              <span>发文字号：{{ itemData.fwzh }}</span>
              <span>发文机构：{{ itemData.deptname }}</span>
              <span
                >发布时间：{{
                  itemData.policypubdate || itemData.publictime
                }}</span
              >
              <div class="tag tag2" v-if="handleTime(itemData) == '已过期'">
                {{ handleTime(itemData) }}
              </div>
              <div class="tag" v-else>{{ handleTime(itemData) }}</div>
            </div>
          </div>

          <div
            class="detail-content"
            v-html="handleContent(itemData.content)"
          ></div>
        </div>
        <!-- right -->
        <div class="detail-right">
          <!--政策的右边,相关政策改为相关通知 1是代表未当前是扶持政策页面-->
          <CardRight
            v-if="deptObject != null"
            :deptObject="deptObject"
            :projectObject="projectData"
            :id="id"
            :code="code"
            :sxid="sxid"
            :zcbj=true
            :policyInfo="itemData"
          ></CardRight>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardRight from "@/components/cardRight";
import { Loading } from "element-ui";
import {handleContent} from "@/utils/setContent.js"

export default {
  components: {
    CardRight,
  },
  data() {
    return {
      showFlag: false,
      projectData: [], //政策的项目数据
      fileName: "",
      fileId: "",
      href: "",
      downloadUrl:
        process.env.VUE_APP_BASE_API +
        "/dev-api/fileManageApi/common/fileView/",
      dialogVisible: false,
      detailFalg: "", //展示详情，如通知公告详情
      id: "",
      offsetTop: 28,
      //部门信息,右边需要显示
      deptObject: null,
      itemData: null,
      type: 0, //请求事项列表传的type参数
      linkType: false,
      detailType: 1,
      deptDataCache: [],
      code: "", //政策编码
      sxid: "", //事项id
    };
  },
  created() {
    this.getFbjgData();
  },
  beforeRouteEnter(to, from, next) {
    if (from.fullPath == "/policyMatchReport") {
      next((vm) => {
        vm.showFlag = true;
      });
    } else {
      next();
    }
  },
  mounted() {
    const that = this;
    let data = sessionStorage.getItem("policyDetailData");
    let detailData = JSON.parse(data);
    const id = detailData.id; // 接收的参数ID
    that.code = detailData.code; //接收的政策编码
    that.sxid = id; //接收的事项编码
    that.detailType = that.$route.query.detailType;
    that.linkType = sessionStorage.getItem("linkType");
    that.getNoticeInfo(id);
    that.getProject(id);
  },
  methods: {
    handleContent,
    // 处理申报时间
    handleTime(item) {
      const NOW = new Date();
      // 未开始/剩余天数/已过期
      let startTime = new Date(item.starttime || item.startTime);
      if (NOW < startTime) return "未开始";

      let endTime = new Date(item.endtime);
      if (endTime < NOW) return "已过期";
      return "有效";
    },
    handleFbjg(deptId) {
      let deptName = "";
      this.deptDataCache.forEach((item) => {
        if (deptId == item.deptId) {
          deptName = item.deptName;
        }
      });
      return deptName;
    },
    // 发布机构数据
    getFbjgData() {
      let url = "/system/dept/zList";
      let params = { priority: "1" };
      this.$httpApi.get(url, params).then((res) => {
        this.deptDataCache = res.data;
      });
    },
    /**
     * 公告详细信息
     * @param {string} id 传项目id
     */
    getNoticeInfo(id) {
      let loadingnoticeDDetail = Loading.service({
        target: this.$refs.noticeDDetail,
      });
      let that = this;
      if (id) {
        this.$httpApi
          .get(`/dev-api/document/${id}`, {})
          .then((res) => {
            console.log("公告详细信息:", res);
            if (res.code == 200) {
              loadingnoticeDDetail.close();
              that.itemData = res.data;
              let data = res.data;
              that.deptObject = {
                deptName: data.deptname,
                deptId: data.deptid,
                fbjg: data.fbjg,
                consultation: data.consultation,
                policyName: data.name,
              };
            }
          })
          .catch((err) => {
            loadingnoticeDDetail.close();
          });
      }
    },
    // 根据政策id查项目
    getProject(id) {
      let url = "/dev-api/business/sq-apply/selectProjectList";
      let params = {
        policyId: id,
        pageSize: 100,
        pageNum: 1,
        serachProject: "",
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          this.projectData = res.list;
        })
        .catch();
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-size {
  background: url("~@/assets/images/底图.png") no-repeat 100% / cover;
}

.banner-info {
  height: 100%;
  display: flex;
  align-items: center;
  .banner-fMax {
    font-size: 40px;
  }

  .banner-other {
    margin-left: 20px;
    font-size: 20px;
  }
}
.policy-detail-content {
  margin-top: 30px;
  min-height: 400px;
}

.detail-wrap {
  width: 100%;
  align-items: flex-start;

  /* 左右 */
  .detail-left {
    flex: 1;
    align-self: flex-start;
    margin: 0 -20px;
    padding: 0 20px;
    overflow: hidden;
  }

  .detail-right {
    width: 300px;
  }
}

.detail-title {
  color: #333;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  .tag {
    vertical-align: middle;
    background: #04c260;
    border-radius: 4px;
    margin-right: 12px;
    font-size: 14px;
    color: #fff;
    padding: 3px 10px;
  }
  .tag2 {
    background: #d81e06;
  }

  h1 {
    width: 100%;
    align-self: flex-start;
    padding-bottom: 10px;
    border-bottom: 0.1875rem solid #0d1c28;
  }

  p {
    padding: 10px 0;
  }

  .title-wrap {
    width: 100%;
    height: 36px;
    color: #aaaaaa;
    span {
      margin-right: 20px;
    }
  }
}

.detail-content {
  line-height: 28px;
  font-size: 18px;
  margin: 20px 0;
  overflow: hidden;
  p {
    text-indent: 2rem;
  }
}

/* 附件 */
.attachment-container {
  width: 100%;

  p {
    padding-bottom: 0.3125rem;
    font-size: 1.25rem;
    border-bottom: 0.1875rem solid #0d1c28;
    color: #1d1e1f;
  }

  a {
    display: block;
    height: 40px;
    padding: 10px 0;
  }
}

/* 底部图片 */
.bottom-img {
  margin-top: 20px;
  width: 100%;
}
//附件下载
.downloadFile {
  width: 100%;
  min-height: 50px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    margin: 6px 0;
  }
}
::v-deep .detail-content div:nth-last-child(1) {
  text-align: right !important;
}
::v-deep .detail-content div:nth-last-child(2) {
  text-align: right !important;
}
</style>
